import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { productMessageSubscriptionStatusMap } from '../../constants';
import { AccountContext } from '../../views/accountsView/account/AccountContext';

const SubscriptionStatusBanner = () => {
  const [accountContext] = AccountContext();
  const productData = accountContext.products[accountContext.currentProductId];
  const statusDefault = productData?.status || productMessageSubscriptionStatusMap.NM.status;

  const MESSAGE = (typeof productData?.blocking_label === 'string' && productData?.blocking_label !== '')
    ? productData?.blocking_label
    : productMessageSubscriptionStatusMap[statusDefault].message;

  if (productData?.status === productMessageSubscriptionStatusMap.NM.status
      || productData?.status === productMessageSubscriptionStatusMap.PB.status
      || productData?.status === undefined) {
    return null;
  }

  return (
    <div>
      <p
        className={`m-0 py-3 alert  ${productMessageSubscriptionStatusMap[statusDefault].style}`}
        style={{
          maxWidth: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <FontAwesomeIcon
          icon={productMessageSubscriptionStatusMap[statusDefault].icon}
          className={productMessageSubscriptionStatusMap[statusDefault].iconStyle}
        />
        <abbr title={MESSAGE} style={{ textDecoration: 'none' }}>{MESSAGE}</abbr>
      </p>
    </div>
  );
};

export default SubscriptionStatusBanner;
