/**
 *  CONSTANTS CONTROL CENTER: Configuration, analytics and operations sections
 *  Source file:
 *  https://docs.google.com/spreadsheets/d/1-jaMgum7TFC-FUnNktZoCXUdf88IaHzuQcYS5SGhZO8/edit#gid=
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */

/* General constants */
/* Definition of external constants (use): JSX to return */
import SITE_CONFIGURATIONS from './siteConfigurations.constants';

import SITE_INTEGRATIONS from './siteIntegrations.constants';

import SITE_DEVELOPER from './siteDeveloper.constants';

import COURSE_TEAM from './courseTeam.constants';

import REPORT_ANALYTICS from './reportAnalytics.constants';

import PLATFORM from './platform.constants';

import SUPPORT from './support.constants';

import LEARNERS from './learners.constants';

import COURSES from './courses.constants';

import SUBSCRIPTION from './subscription.constants';

import ORGANIZATION from './organizations.constants';

import TEST_CONFIG from './test.constants';

export {
  COMPANY_INFORMATION,
  DEFAULT_MAX_SIZE_PAGINATION,
  productMessageSubscriptionStatusMap,
  photoBgApiUrl,
  edunextLogoFileByColor,
  openedxLogoByColor,
  SERVICE_ERROR_MESSAGES,
  DEFAULT_RESIZE_IMG_UPLOAD_DIMENSIONS,
} from './general.constants';

export default {
  ...SITE_CONFIGURATIONS,
  ...SITE_INTEGRATIONS,
  ...SITE_DEVELOPER,
  ...REPORT_ANALYTICS,
  ...COURSE_TEAM,
  ...PLATFORM,
  ...SUPPORT,
  ...LEARNERS,
  ...COURSES,
  ...SUBSCRIPTION,
  ...ORGANIZATION,
  ...TEST_CONFIG,
};
